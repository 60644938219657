import GlobalConfig from '@services/globalConfig'
import request from '@services/request.service'

export interface IPageContentPayload {
  id?: string
  slug: string
  workingVersion: boolean
  channel: string
}
export interface IRefreshPagePayload {
  data?: any
}
export interface IRiskDetailsPayload {
  data?: any
}
export interface ICreateOrderApiPayload {
  data?: any
}
export const retrievePageContentApi = (data: IPageContentPayload) => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/page`),
  data,
})
export const refreshPageContentApi = (data: IRefreshPagePayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/refresh-page`),
  data,
})

export const getRiskDetailsApi = (data: IRiskDetailsPayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/gokwik_rto`),
  data,
})

export const createOrderApi = (data: ICreateOrderApiPayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/create_order`),
  data,
})

export class HomePageService {
  static async retrievePageContent(params: IPageContentPayload) {
    const res = await request(retrievePageContentApi(params))
    return res
  }
  static async refreshPageContent(params: IRefreshPagePayload) {
    const res = await request(refreshPageContentApi(params))
    return res
  }
  static async getRiskDetailsContent(params: IRefreshPagePayload) {
   try {
     const res = await request(getRiskDetailsApi(params))
     return res
   } catch (error) {
     return null
   }
  }

  static async createOrderContent(params: IRefreshPagePayload) {
    try {
      const res = await request(createOrderApi(params))
      return res
    } catch (error) {
      return null
    }
  }
}
